/*globals $*/
(function (BDM, realtedInfo) {
  'use strict';

  var $el = {
    //stored elements
    realtedInfo: $('.related-information__tabs')
  };

  realtedInfo.init = function () {
    if ($el.realtedInfo.length > 0) {
      var id = $('.related-information').data('guid');

      $(document).on(
        'click',
        '.related-information__tabs button[role="tab"]',
        function (e) {
          e.preventDefault();
          var $thisButton = $(this);
          var target = $thisButton.attr('aria-controls');

          $thisButton
            .addClass('active')
            .attr('aria-selected', 'true')
            .siblings()
            .removeClass('active')
            .attr('aria-selected', 'false');

          $('#' + target)
            .addClass('active')
            .siblings()
            .removeClass('active');
        }
      );

      $.ajax({
        type: 'GET',
        dataType: 'JSON',
        url: window.location.origin + '/api/pagemodules/RelatedInfo',
        data: 'itemId=' + id,
        beforeSend: function () {
          var loader =
            '<div class="icon__loading"><p>Loading</p><div class="snippet" data-title=".dot-falling"><div class="stage"><div class="dot-falling"></div></div></div></div>';
          var container = $('.related-information__tabs');
          container.html(loader);
        },
        success: function (data) {
          let container = $('.related-information__tabs');
          let items = Object.fromEntries(
            Object.entries(data).filter(
              ([key, value]) => value !== undefined && value !== null
            )
          );
          let size = Object.keys(items).length;
          let count = 0;
          if (size > 0) {
            let content = `<div class="tabs" aria-label="Related Information Categories"><div role="tablist" class="nav nav-tabs tabs__tabs" style="margin-bottom: 40px;">`;
            container.html('');

            $.each(items, function (index, value) {
              if (value !== null) {
                var active = count === 0 ? 'active' : '';
                var selected = count === 0 ? 'true' : 'false';
                content += `<button id="tab-${count}" type="button" role="tab" aria-selected="${selected}" aria-controls="tabpanel-${count}" class="nav-link ${active}"><span class="focus">${index}</span></button>`;
                count++;
              }
            });

            content += '</div>';
            count = 0;

            $.each(items, function (index, value) {
              if (value !== null) {
                var active = count === 0 ? 'active' : '';
                content += `<div id="tabpanel-${count}" role="tabpanel"  class="tabs__content tab-pane ${active}"><div class="bn-carousel carousel slide" id="carousel-${count}"><div class="carousel-control-prev" role="button" aria-label="Previous"><i class="carousel-control-prev-icon"></i></div>`;

                if (index === 'events') {
                  $.each(this.items, function () {
                    content += `<div class="card__event carousel-item"><div class="card__event-content"><div class="card__text"><div class="card__subtitle">${this.subtitle}</div><div class="card__title"><h3 itemprop="name"><a class="card__title-link" href="${this.url}" style="display: inline;">${this.title}</a></h3></div><div class="card__copy">${this.copy}</div></div><div class="card__information"><div class="card__date">${this.date}</div><div class="card__time">${this.time}</div><div class="card__addlink"><div title="Add to Calendar" class="addeventatc" aria-live="polite"><span class="icon icon__calendar" aria-hidden="true"></span>Add to Calendar<span itemprop="startDate" datetime="${this.startDate2}" class="start">${this.startDate}</span><span itemprop="endDate" datetime="${this.endDate2}" class="end"> ${this.endDate}</span><span class="timezone">${this.timezone}</span><span itemprop="name" class="title">${this.title}</span><span itemprop="description" class="description">${this.eventSummary}</span><span itemprop="location" class="location">${this.location}</span><span itemprop="organizer" class="organizer">${this.contactName}</span><span class="organizer_email">${this.email}</span><span class="all_day_event">false</span><span class="date_format">MM/DD/YYYY</span></div></div><div class="card__cta"><a class="btn btn-primary-carat" href="${this.register}" aria-label="Register" title="" target="_blank" style="display: inline-block;">Register</a></div></div></div></div>`;
                  });
                } else if (index === 'news' || index === 'blogs') {
                  $.each(value.items, function () {
                    content += `<div class="card carousel-item"><a  href="${
                      this.url
                    }" style="display: block;"><img loading="lazy" src="${
                      this.image
                    }" alt="${
                      this.alt
                    }" class="card-img-top"><div class="card-body"><h5 class="card__subtitle">${
                      this.subtitle
                    }</h5><h3 class="card__title">${
                      this.title ? this.title : ''
                    }</h3><p class="card-text">${
                      this.description
                    }</p></div></a></div>`;
                  });
                } else if (index === 'programs') {
                  $.each(value.items, function () {
                    content += `<div class="card__program carousel-item"><div class="card__program-content ${this.copy.toLowerCase()}"><div class="card__text"><div class="card__title"><h3><a class="card__title-link" href="${
                      this.url
                    }" style="display: inline;">${
                      this.title
                    }</a></h3></div><div class="card__copy">${
                      this.copy
                    }</div></div><div class="card__information"><div class="card__description"><p>${
                      this.description
                    }</p></div><div class="card__program-ways">`;
                    $.each(this.ways, function () {
                      content += `<div class="ways__value degree-finder__ways-value" data-way="${this.name.toLowerCase()}"><span class="icon icon__${this.name.toLowerCase()}" aria-hidden="true"></span><p>${
                        this.name
                      }</p><span class="tagline">${this.tagline}</span></div>`;
                    });
                    content += `</div></div></div></div>`;
                  });
                }

                count++;
                content +=
                  '<div class="carousel-control-next" role="button" aria-label="Next"><i class="carousel-control-next-icon"></i></div></div></div>';
              }
            });

            content += '</div>';
            container.html(content);
            activateCarousel();
          }
        }
      });

      function activateCarousel() {
        var autoplayDelay = 15000;
        var slideTimeouts = [];
        var activeSlidesByCarousel = {};

        var types = [
          '#carousel-0 .card',
          '#carousel-1 .card',
          '.card__event',
          '.card__program'
        ];

        function updateActiveSlides() {
          var width = $(window).width();
          if (width < 769) {
            return 1;
          } else if (width < 992) {
            return 2;
          } else {
            return 3;
          }
        }
        function initializeSlides($slides, activeSlides) {
          for (var i = 0; i < activeSlides.length; i++) {
            $slides.eq(activeSlides[i]).addClass('active');
          }
          activeSlidesByCarousel[$slides.parents('.bn-carousel').attr('id')] =
            activeSlides;
        }

        $(window).resize(function () {
          types.forEach(function (selector) {
            var $slides = $(selector);
            var newActiveSlideCount = updateActiveSlides();

            var oldActiveSlides =
              activeSlidesByCarousel[
                $slides.parents('.bn-carousel').attr('id')
              ];
            var newActiveSlides = oldActiveSlides.slice(0, newActiveSlideCount);

            while (newActiveSlides.length < newActiveSlideCount) {
              newActiveSlides.push(
                (newActiveSlides[newActiveSlides.length - 1] + 1) %
                  $slides.length
              );
            }

            if ($slides.length <= updateActiveSlides()) {
              $slides
                .closest('.bn-carousel')
                .find('.carousel-control-prev, .carousel-control-next')
                .hide();
              var carouselIndex = types.indexOf($slides.selector);
              clearTimeout(slideTimeouts[carouselIndex]);
            } else {
              $slides
                .closest('.bn-carousel')
                .find('.carousel-control-prev, .carousel-control-next')
                .show();
              clearTimeout(slideTimeouts[types.indexOf($slides.selector)]);
              slideTimeouts[types.indexOf($slides.selector)] = setTimeout(
                function () {
                  shiftSlide($slides, 1, updateActiveSlides());
                },
                autoplayDelay
              );
            }

            $slides.removeClass('active');
            newActiveSlides.forEach(function (slideIndex) {
              $slides.eq(slideIndex).addClass('active');
            });
            activeSlidesByCarousel[$slides.parents('.bn-carousel').attr('id')] =
              newActiveSlides;
          });
        });

        function shiftSlide($slides, direction, newActiveSlideCount) {
          clearTimeout(slideTimeouts[types.indexOf($slides.selector)]);
          $slides.removeClass('active');
          var activeSlides =
            activeSlidesByCarousel[$slides.parents('.bn-carousel').attr('id')];

          if (newActiveSlideCount === 1) {
            var currentIndex = activeSlides[0];
            var nextIndex =
              (currentIndex + direction + $slides.length) % $slides.length;
            activeSlides = [nextIndex];
          } else {
            if (activeSlides.length > newActiveSlideCount) {
              activeSlides = activeSlides.slice(0, newActiveSlideCount);
            }

            if (direction === 1) {
              if (
                activeSlides[activeSlides.length - 1] ===
                $slides.length - 1
              ) {
                activeSlides = [];
                for (var i = 0; i < newActiveSlideCount; i++) {
                  activeSlides.push(i);
                }
              } else {
                activeSlides.shift();
                activeSlides.push(
                  (activeSlides[activeSlides.length - 1] + 1) % $slides.length
                );
              }
            } else {
              if (activeSlides[0] === 0) {
                activeSlides = [];
                for (
                  var i = $slides.length - newActiveSlideCount;
                  i < $slides.length;
                  i++
                ) {
                  activeSlides.push(i);
                }
              } else {
                activeSlides.pop();
                activeSlides.unshift(
                  (activeSlides[0] - 1 + $slides.length) % $slides.length
                );
              }
            }
          }

          activeSlides.forEach(function (slideIndex) {
            $slides.eq(slideIndex).addClass('active');
          });

          activeSlidesByCarousel[$slides.parents('.bn-carousel').attr('id')] =
            activeSlides;

          slideTimeouts[types.indexOf($slides.selector)] = setTimeout(
            function () {
              shiftSlide($slides, 1, updateActiveSlides());
            },
            autoplayDelay
          );
        }

        types.forEach(function (selector) {
          var $slides = $(selector);
          var activeSlides = [];
          for (var i = 0; i < updateActiveSlides(); i++) {
            activeSlides.push(i);
          }
          initializeSlides($slides, activeSlides);

          // hide arrows if there is no more slides
          if ($slides.length <= updateActiveSlides()) {
            $slides
              .closest('.bn-carousel')
              .find('.carousel-control-prev, .carousel-control-next')
              .hide();
            var carouselIndex = types.indexOf($slides.selector);
            clearTimeout(slideTimeouts[carouselIndex]);
          } else {
            slideTimeouts.push(
              setTimeout(function () {
                shiftSlide($slides, 1, updateActiveSlides());
              }, autoplayDelay)
            );
          }
        });

        $(document).on('click', '.carousel-control-next', function (e) {
          e.preventDefault();
          var $carousel = $(this).closest('.bn-carousel');
          var selector;
          if ($carousel.find('.card').length) {
            selector = '#' + $carousel.attr('id') + ' .card';
          } else if ($carousel.find('.card__event').length) {
            selector = '#' + $carousel.attr('id') + ' .card__event';
          } else if ($carousel.find('.card__program').length) {
            selector = '#' + $carousel.attr('id') + ' .card__program';
          }
          var $slides = $(selector);
          shiftSlide($slides, 1, updateActiveSlides());
        });

        $(document).on('click', '.carousel-control-prev', function (e) {
          e.preventDefault();
          var $carousel = $(this).closest('.bn-carousel');
          var selector;
          if ($carousel.find('.card').length) {
            selector = '#' + $carousel.attr('id') + ' .card';
          } else if ($carousel.find('.card__event').length) {
            selector = '#' + $carousel.attr('id') + ' .card__event';
          } else if ($carousel.find('.card__program').length) {
            selector = '#' + $carousel.attr('id') + ' .card__program';
          }
          var $slides = $(selector);
          shiftSlide($slides, -1, updateActiveSlides());
        });
      }
    }
  };
})(
  (window.BDM = window.BDM || {}),
  (window.BDM.realtedInfo = window.BDM.realtedInfo || {})
);
$(function () {
  window.BDM.realtedInfo.init();
});
